import React, { useContext } from "react";
import { BiArrowBack } from "react-icons/bi";

const BusquedaSellos = () => {

  
  return (
    <>
    <div className="container-ajustesCot">
      Busqueda
        
      </div>
    </>
  )
}

export default BusquedaSellos